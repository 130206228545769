<template>
  <div class="app-container">
    <el-header>
      <div class="left">
        <img style="width: 140px; height: 50px" src="../assets/yw-logo.png" alt="" />
      </div>
      <div class="right">
        <div v-for="item in topList" :key="item.value">
          <div class="text" v-if="item.default == true" style="color: #4bd3d5" @click="onClick(item)">
            {{ item.text }}
          </div>
          <div class="text" v-else @click="onClick(item)">{{ item.text }}</div>
        </div>
      </div>
    </el-header>
    <el-main>
      <div v-if="show">
        <div class="img">
          <img style="width: 210px; height: 90px" src="../assets/synopsis.png" alt="" />
        </div>
        <div class="writing">
          江苏易为信创科技发展有限公司是一家集软件研发、系统集成、建筑智能化、信息技术服务为一体的高科技技术企业。
          为政务、教育、医疗、中大型企业提供信息化、数字化、智慧化建设整体解决方案。
        </div>
        <div class="writing">
          易为人秉承“诚信为本，服务至上”的理念，专注于产品研发、技术创新，已开发完成计算机软件著作权、软件产品等十
          多项知识产权，在数据备份、操作审计、运维管理、数据中心监控等安全运维相关领域上形成了一套完整的、成熟的软
          件产品及实施服务。公司始终把专业技术、服务质量放在首位，通过运行ISO质量管理标准等多个认证体系，并实践于项
          目管理及服务过程。
        </div>
        <div class="writing">
          易为信创经过多年的技术研发与创新实践，凭借雄厚的技术实力、严格的管理制度及完善的服务体系，一直保持着健康、
          稳定、快速的发展。未来，易为人将迎接新的挑战，坚持不懈地投入软件产品研发、创造机遇、体现价值，实现员工与
          企业的共同发展。
        </div>
      </div>
      <div v-else style="text-align: center; font-size: 30px; margin-top: 30px; color: #4bd3d5">开发中......</div>
    </el-main>
    <!--  底部  -->
    <div class="el-login-footer">
      <!-- <span>备案号：苏ICP备2021049214号-1</span> -->
      <el-link href="https://beian.miit.gov.cn" target="_blank">备案号：苏ICP备2021049214号-1</el-link>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      topList: [
        {
          value: 0,
          text: '首页',
          default: true
        },
        {
          value: 1,
          text: '关于我们',
          default: false
        },
        {
          value: 2,
          text: '服务案例',
          default: false
        },
        {
          value: 3,
          text: '管理模式',
          default: false
        },
        {
          value: 4,
          text: '发展历程',
          default: false
        },
        {
          value: 5,
          text: '创业团队',
          default: false
        }
      ],
      show: true
    }
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {},
  methods: {
    /** 切换 */
    onClick(row) {
      this.topList.forEach((el) => {
        if (row.value == el.value) {
          el.default = true
        } else {
          el.default = false
        }
      })
      if (row.value == 0) {
        this.show = true
      } else {
        this.show = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.app-container {
  padding: 30px 0;
  .el-header {
    padding: 0 18%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right {
      display: flex;
      color: #fff;
      .text {
        margin-left: 40px;
        cursor: pointer;
      }
    }
  }
  .el-main {
    padding: 0 18%;
    margin-top: 8%;
    color: #3a7e98;
  }
  .writing {
    margin: 30px 24% 0 0;
  }
  .el-login-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: '思源黑体';
    font-size: 14px;
    letter-spacing: 1px;
    .el-link {
      color: #fff;
    }
  }
}
</style>
